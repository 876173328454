import { LARGE_PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/LargeProductImage/Base';
import {
  EXHIBITOR_ACTION_BUTTON_MESSAGE_FRAGMENT,
} from '@/graphql/_Fragments/Exhibitor/ActionButtons/Message';

export const LARGE_PRODUCT_ACTION_BUTTON_MESSAGE_FRAGMENT = `
  fragment largeProductActionButtonMessageFragment on LargeProduct {
    uid
    name
    images(first: 1, orderBy: [id_asc]) {
      ...largeProductImageBaseFragment
    }
    exhibitor {
      ...exhibitorActionButtonMessageFragment
    }
  }
  ${LARGE_PRODUCT_IMAGE_BASE_FRAGMENT}
  ${EXHIBITOR_ACTION_BUTTON_MESSAGE_FRAGMENT}
`;
